import React, { Component } from 'react';
import asyncJS from 'async';
import { Button, Callout, Intent, Position, Switch, Toast2, OverlayToaster } from '@blueprintjs/core';
import queryString from 'query-string';
import * as styles from './madara.module.less';
import API from '../../config';
import Logger from '../../models/logger';
import AxiosClient from '../../lib/AxiosClient';
import AppToaster from '../../lib/Toaster';

/**
 * An empty component to speed up new component creation
 */
class Madara extends Component {
  constructor(props) {
    super(props);

    /*
    Using query params to pass info into this component:
    uid = "User ID" = the ID of the user
     */
    const parsedQueryParams = queryString.parse(location.search);

    this.state = {
      showMadara: false, // By default, don't show anything as a security by obscurity measure
      busy: false,
      userWorkbooks: null,
      userWorkspaces: [],
      userID: parsedQueryParams.uid,
      toasts: [],
    };

    /* Create our logger */
    this.logger = new Logger('Madara');

    /* Set up the toaster */
    this.toaster = AppToaster;
    this.refHandlers = {
      toaster: ref => this.toaster = ref,
    };
  }

  async componentDidMount() {
    if (this.state.userID && this.state.userID.length > 0) {
      await this.loadUserWorkspaces(); // Load the workspaces for the user
      await this.loadWorkspaceWorkbooks(); // Load the workbooks
    } else {
      // We don't have enough data to show interface
      window.location = 'https://en.wikipedia.org/wiki/Madara_Rider';
    }
  }

  async loadWorkspaceWorkbooks() {
    await asyncJS.eachLimit(this.state.userWorkspaces, 1, async (workspace) => {
      try {
        const response = await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/user-workbooks`, {
          userID: this.state.userID,
          workspaceID: workspace.id,
        });

        const userWorkbooks = response.data;

        console.log(`userWorkbooks: ${JSON.stringify(userWorkbooks)}`);

        this.setState({
          userWorkbooks: userWorkbooks,
          showMadara: true,
        });
      } catch (err) {
        this.logger.error('Madara - componentDidMount', `Error loading user workbooks: ${err}`);
        window.location = 'https://en.wikipedia.org/wiki/Madara_Rider';
      }
    });
  }

  /** Load the Workspaces that the user is a member of */
  async loadUserWorkspaces() {
    try {
      const response = await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/user-workspaces`, {
        userID: this.state.userID,
      });

      const userWorkspaces = response.data;

      console.log(`userWorkspaces: ${JSON.stringify(userWorkspaces)}`);

      this.setState({
        userWorkspaces,
      });

      await this.loadIntegrationConfigs(); // Make a call to get the integration configs for our workspaces
    } catch (err) {
      this.logger.error('Madara - loadUserWorkspaces', `Error loading user workspaces: ${err}`);

      this.toaster.show({
        message: `Error loading workspaces: ${err}`,
        icon: 'issue',
        intent: Intent.DANGER,
      });
    }
  }

  /**
   * Loads any active Subscriptions for any Shopify stores that we have loaded
   * @returns {Promise<void>}
   */
  async loadShopifyActiveSubscriptions() {
    await asyncJS.eachLimit(this.state.userWorkspaces, 1, async (workspace) => {
      await asyncJS.eachLimit(workspace.shopifyIntegrationConfigs, 1, async (shopifyIntegrationConfig) => {
        try {
          const shopifyStoreActiveSubscriptionResponse = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/billing/shopify/${workspace.id}/${shopifyIntegrationConfig.target_id}/activeSubscriptions`);

          console.log(`shopifyStoreActiveSubscriptionResponse for ${shopifyIntegrationConfig.target_id} => ${JSON.stringify(shopifyStoreActiveSubscriptionResponse)}`);

          shopifyIntegrationConfig.activeSubscriptions = shopifyStoreActiveSubscriptionResponse.data;
        } catch (err) {
          console.log(`Error getting store active subscription: ${err}`);
        }
      });
    });

    /* Set the workspaces to themselves, to ensure that we trigger a paint cycle */
    this.setState({
      workspaces: this.state.userWorkspaces,
    });
  }

  /** Load the integration configs for the user's workspaces */
  async loadIntegrationConfigs() {
    const newWorkspaces = [];

    await asyncJS.eachLimit(this.state.userWorkspaces, 1, async (workspace) => {
      try {
        const response = await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/workspace-integration-configs`, {
          workspaceId: workspace.id,
        });

        const integrationConfigs = response.data;
        console.log(`integrationConfigs: ${JSON.stringify(integrationConfigs)}`);

        // Store all the integration configs for the workspace
        workspace.integrationConfigs = integrationConfigs;

        // Store the Shopify integration configs separately
        workspace.shopifyIntegrationConfigs = [];
        integrationConfigs.forEach((config) => {
          if (config.target === 'shopify') {
            workspace.shopifyIntegrationConfigs.push(config);
          }
        });


        newWorkspaces.push(workspace);
      } catch (err) {
        this.logger.error('Madara - loadIntegrationConfigs', `Error loading integration configs for ${workspace.id}: ${err}`);

        this.toaster.show({
          message: `Error loading integration configs for ${workspace.id}: ${err}`,
          icon: 'issue',
          intent: Intent.DANGER,
        });
      }
    });

    // Set the new workspaces with the configs
    this.setState({
      userWorkspaces: newWorkspaces,
    }, () => {
      this.loadShopifyActiveSubscriptions(); // Load all the active Subscriptions we have for any connected stores
    });
  }

  async addUserWorkbook(workspaceID) {
    try {
      const response = await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/add-user-workbook`, {
        userID: this.state.userID,
        workspaceID: workspaceID,
      });


      this.setState({
        userWorkbooks: this.state.userWorkbooks.concat(response.data),
      });
    } catch (err) {
      this.logger.error('Madara - addUserWorkbook', `Error adding workbook: ${err}`);

      this.toaster.show({
        message: `Error loading workspaces: ${err}`,
        icon: 'issue',
        intent: Intent.DANGER,
      });
    }
  }

  /** Adds a Subscription of the given plan to the given store */
  async addSubscription(workspaceId, storeId, planId) {
    try {
      await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/add-shopify-subscription`, {
        userId: this.state.userID,
        workspaceId: workspaceId,
        storeId: storeId,
        planId: planId,
      });

      await this.loadShopifyActiveSubscriptions(); // Force a re-load of active Shopify subscriptions
    } catch (err) {
      console.log('Error adding subscription');
    }
  }


  async toggleStoreSyncPaused(storeId) {
    console.log(`toggleStoreSyncPaused: ${storeId}`);

    this.setState({ busy: true });

    try {
      await AxiosClient.getInstance().post(`${API.API_URL}/2.0/madara/toggle-store-sync-paused`, {
        userId: this.state.userID,
        storeId: storeId,
      });

      await this.loadIntegrationConfigs(); // Force a re-load of Shopify integration configs

      this.setState({ busy: false });
    } catch (err) {
      console.log(`Error toggling paused status ${err}`);
    }
  }

  /** Queues up a task to reload all the store's data S>L */
  async reloadShopifyStoreData(store, dataType) {
    try {
      await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${store}/reloadStoreData/${dataType}`);

      /* Show a toast to the user */
      this.toaster.show({
        message: `Queued reload of ${dataType} data for ${store}`,
        icon: 'saved',
        intent: Intent.SUCCESS,
      });

      /* Now disable the reload button until the drawer is closed, to prevent accidental double ups */
      this.setState({ [`disabledStoreReload_${dataType}_${store}`]: true });
    } catch (err) {
      this.logger.error('reloadShopifyStoreData', `Error reloading shopify store data: ${err}`);

      this.toaster.show(err);
    }
  }

  render() {
    return (
      <div className={styles.component}>
        {/* Toast component*/}
        <OverlayToaster position={Position.BOTTOM} ref={this.refHandlers.toaster}>
          {/* "Toasted!" will appear here after clicking button. */}
          {this.state.toasts.map(toast => <Toast2 {...toast} />)}
        </OverlayToaster>

        {/* We are not allowed to show the interface*/}
        {!this.state.showMadara && <div></div>}

        {/* We are allowed to show the interface*/}
        {this.state.showMadara && <div>
          <strong>Madara</strong>

          <div className={styles.integrations}>
            {this.state.userWorkspaces.map(workspace => <div key={workspace.id}>

              <div className={styles.workspaceName}>Workspace: <strong>{workspace.id}</strong> - <strong>{workspace.name}</strong></div>

              {/* List out the Workbooks that the user has access to, along with links*/}
              {this.state.userWorkbooks && <div className={styles.workspaceWorkbooks}>
                <h3>User workbooks</h3>
                <div>
                  {this.state.userWorkbooks.length === 0 && <div>
                    User has no workbooks
                  </div>}

                  <div className={styles.workbookList}>
                    {this.state.userWorkbooks.map(workbook => <div className={styles.workbookEntry} key={workbook.id}>
                      <a href={`/workbook/${workbook.editions[0].id}/${workbook.editions[0].worksheets[0].id}?mvr=da`} target={'_blank'} rel="noreferrer">{workbook.name}</a>
                    </div>)}

                    <Button className={styles.addWorkbookButton} onClick={() => {
                      this.addUserWorkbook(workspace.id);
                    }} outlined={true} text={'Add Workbook'}/>
                  </div>
                </div>
              </div>}

              {workspace.shopifyIntegrationConfigs && workspace.shopifyIntegrationConfigs.length > 0 &&
                <div className={styles.workspaceIntegrationConfigs}>
                  <h3>Shopify stores</h3>
                  {workspace.shopifyIntegrationConfigs.map(shopifyIntegrationConfig =>
                    <div className={styles.integrationConfig} key={shopifyIntegrationConfig.id}>
                      <Callout intent={Intent.PRIMARY}>
                        <div className={styles.targetId}>{shopifyIntegrationConfig.target_id}</div>
                        <div className={styles.dataLoadingControls}>
                          <div className={styles.button}>
                            <Button disabled={this.state[`disabledStoreReload_products_${shopifyIntegrationConfig.target_id}`]} small={false}
                              text={'Reload product, collections, and inventory Shopify data'} onClick={() => {
                                this.reloadShopifyStoreData(shopifyIntegrationConfig.target_id, 'products');
                              }}/></div>
                          <div className={styles.button}>
                            <Button disabled={this.state[`disabledStoreReload_orders_${shopifyIntegrationConfig.target_id}`]} small={false}
                              text={'Reload orders Shopify data'} onClick={() => {
                                this.reloadShopifyStoreData(shopifyIntegrationConfig.target_id, 'orders');
                              }}/></div>
                          <div className={styles.button}>
                            <Button disabled={this.state[`disabledStoreReload_customers_${shopifyIntegrationConfig.target_id}`]} small={false}
                              text={'Reload customers Shopify data'} onClick={() => {
                                this.reloadShopifyStoreData(shopifyIntegrationConfig.target_id, 'customers');
                              }}/></div>
                        </div>
                        <div className={styles.subscriptionControls}>
                          {(!shopifyIntegrationConfig.activeSubscriptions || shopifyIntegrationConfig.activeSubscriptions.length === 0) &&
                            <div>

                              <div>No active subscription found</div>

                              {/*<div className={styles.button}>*/}
                              {/*  <Button small={false}*/}
                              {/*          text={'Add - Launch 12 month free'} onClick={async () => {*/}
                              {/*    await this.addSubscription(workspace.id, shopifyIntegrationConfig.target_id, 'launch-free-12-months');*/}
                              {/*  }}/></div>*/}

                              <div className={styles.button}>
                                <Button small={false} text={'Add - Bulk Editor Free'} onClick={async () => {
                                  await this.addSubscription(workspace.id, shopifyIntegrationConfig.target_id, 'bulk-editor-free');
                                }}/></div>

                              <div className={styles.button}>
                                <Button small={false} text={'Add - Exporter Free'} onClick={async () => {
                                  await this.addSubscription(workspace.id, shopifyIntegrationConfig.target_id, 'exporter-free');
                                }}/></div>

                              <div className={styles.button}>
                                <Button small={false} text={'Add - Analytics Free'} onClick={async () => {
                                  await this.addSubscription(workspace.id, shopifyIntegrationConfig.target_id, 'analytics-free');
                                }}/></div>
                            </div>}

                          {shopifyIntegrationConfig.activeSubscriptions && <div>
                            {shopifyIntegrationConfig.activeSubscriptions.map(activeSubscription => <div>
                              Subscription
                              on <strong>{activeSubscription.planName}</strong> - {activeSubscription.planId}
                            </div>)}

                          </div>}

                          {/* {JSON.stringify(shopifyConfig.activeSubscription)}*/}
                        </div>
                        <div className={styles.adminControls}>
                          <Switch innerLabel={shopifyIntegrationConfig.shopifyStore.paused ? 'Yes' : 'No'} label={'Store sync paused'} inline={true} disabled={this.state.busy} checked={shopifyIntegrationConfig.shopifyStore.paused} onChange={async () => {
                            // console.log(`shopifyIntegrationConfig.shopifyStore.id: ${shopifyIntegrationConfig.shopifyStore.id}`)
                            await this.toggleStoreSyncPaused(shopifyIntegrationConfig.shopifyStore.id);
                          }}/>
                        </div>
                      </Callout>
                    </div>)}
                </div>}
            </div>)}
          </div>
        </div>}
      </div>
    );
  }
}

export default Madara;
