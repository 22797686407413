import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../config';
import * as styles from './workbooksPage.module.less';
import LoadingAnimation from '../../loadingAnimation';
import Header from './header';
import WorkbooksCatalog from './workbooksCatalog';
import ConnectedStores from './connectedStores';
import EmptyWorkbooksCatalog from './emptyWorkbooksCatalog';
import HelpArticles from './helpArticles';
import AddShopifyLinkedWorkbookDialog from './addShopifyLinkedWorkbookDialog';
import AxiosClient from '../../../lib/AxiosClient';
import AuthService from '../../../lib/AuthService';

/**
 * The Workbooks Catalog Component within the Home Page
 */
class WorkbooksPage extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openIntegrationsCatalogScreen: props.openIntegrationsCatalogScreen,
      showError: props.showError,
    };

    this.state = {
      workspaceId: props.workspaceId,
      workbooks: props.workbooks,
      masterEditions: props.masterEditions,
      userWorkbooks: [],
      sharedWorkbooks: [],
      showBlockingOverlay: false,
      storesSubscriptions: props.storesSubscriptions,
      storesAppsInstalled: props.storesAppsInstalled,
      isAddShopifyLinkedWorkbookDialogOpened: false,
    };

    // Store the Auth provider
    this.authProvider = AuthService.getInstance().provider;
  }

  async componentDidMount() {
    /* Check if we have workbooks */
    if (!this.state.workbooks) {
      return;
    }

    const workbooks = this.state.workbooks;

    /* Store the workbooks where user is the owner and sort it */
    const userWorkbooks = workbooks
      .filter((workbook) => {
        if (!workbook.relationship || workbook.relationship !== 'owner') {
          return false;
        }
        return workbook;
      }).sort((a, b) => a.name.localeCompare(b.name));

    /* Store the workbooks where user is collaborator and sort it */
    const sharedWorkbooks = workbooks
      .filter((workbook) => {
        if (!workbook.relationship || workbook.relationship !== 'collaborator') {
          return false;
        }

        return workbook;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.setState({
      workbooks,
      userWorkbooks,
      sharedWorkbooks,
    });
  }

  openAddShopifyWorkbookDialog = () => {
    this.setState({ isAddShopifyLinkedWorkbookDialogOpened: true });
  }

  closeAddShopifyWorkbookDialog = () => {
    this.setState({ isAddShopifyLinkedWorkbookDialogOpened: false });
  }

  /**
   * Creates workbook and redirects to it based on its template
   * @param template
   * @param storeId
   * @returns {Promise<void>}
   */
  createWorkbook = async (template, storeId) => {
    this.setState({ showBlockingOverlay: true }, async () => {
      try {
        const response = await AxiosClient.getInstance().post(`${API.API_URL}/1.0/workbooks/`, {
          workspaceId: this.state.workspaceId,
        });

        const masterEdition = response.data.masterEdition;

        let urlToRedirect = `/workbook/${masterEdition.id}/${masterEdition.worksheets[0].id}?new=true&`;

        if (template && template !== 'start-from-scratch' && storeId) {
          urlToRedirect += `template=${template}&storeId=${storeId}`;
        }

        this.props.history.push(urlToRedirect);
      } catch (err) {
        this.parentFunctions.showError(err);
      }
    });
  }

  render() {
    return (
      <div className={styles.component}>

        {/* Header with welcome message and create workbook button if needed */}
        <Header
          hasWorkbooks={this.state.workbooks && this.state.workbooks.length > 0}
          createWorkbook={this.createWorkbook}
          openAddShopifyWorkbookDialog={this.openAddShopifyWorkbookDialog}
        />

        {/* Pasting data overlay & spinner*/}
        {this.state.showBlockingOverlay && <div className={styles.blockingOverlayContainer}>
          <div className={styles.blockingOverlay}>
            <div>
              <LoadingAnimation/>
            </div>
            <div className={styles.description}>
              Please wait, we will redirect you to the new workbook
            </div>
          </div>
        </div>}

        {/* Shows workbooks where user is the owner */}
        <div className={styles.sectionHeading}>
          <h2>Your workbooks</h2>
        </div>
        {this.state.userWorkbooks && this.state.userWorkbooks.length > 0 && <div className={styles.workbookCatalogContainer}>
          <WorkbooksCatalog
            workbooks={this.state.userWorkbooks}
            masterEditions={this.state.masterEditions}
          />
        </div>}

        {/* If the user doesn't own any workbooks, then show him the onboarding workbook creation options */}
        {(!this.state.userWorkbooks || this.state.userWorkbooks.length <= 0) && <div className={styles.workbookCatalogContainer}>
          <EmptyWorkbooksCatalog
            createWorkbook={this.createWorkbook}
            openAddShopifyWorkbookDialog={this.openAddShopifyWorkbookDialog}
          />
        </div>}

        {/* If we had any user workbooks to show add some space */}
        {this.state.userWorkbooks && this.state.userWorkbooks.length > 0 && <div style={{ margin: '50px' }}></div>}

        {/* Shows workbooks where user is collaborator */}
        {this.state.sharedWorkbooks && this.state.sharedWorkbooks.length > 0 && <div className={styles.workbookCatalogContainer}>
          <div className={styles.sectionHeading}>
            <h2>Workbooks shared with you</h2>
          </div>
          <WorkbooksCatalog
            workbooks={this.state.sharedWorkbooks}
            masterEditions={this.state.masterEditions}
          />
        </div>}

        {/* Shows the connected stores */}
        {this.authProvider !== 'shopify' && <ConnectedStores
          openIntegrationsCatalogScreen={this.parentFunctions.openIntegrationsCatalogScreen}
          storesSubscriptions={this.state.storesSubscriptions}
        />}

        {/* If user does not have any workbooks show some help articles */}
        {(!this.state.workbooks || this.state.workbooks.length <= 0) && <div>
          <div className={styles.sectionHeading}>
            <h2>Help Articles</h2>
          </div>
          <HelpArticles />
        </div>}

        {/* Shows the add shopify linked workbook dialog */}
        {this.state.isAddShopifyLinkedWorkbookDialogOpened &&
          <AddShopifyLinkedWorkbookDialog
            showError={this.parentFunctions.showError}
            storesSubscriptions={this.state.storesSubscriptions}
            storesAppsInstalled={this.state.storesAppsInstalled}
            createWorkbook={this.createWorkbook}
            closeAddShopifyWorkbookDialog={this.closeAddShopifyWorkbookDialog}
          />}
      </div>
    );
  }
}

export default withRouter(WorkbooksPage);
