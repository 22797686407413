import React from 'react';
import queryString from 'query-string';

const Spreadsheet = React.lazy(() => import ('./spreadsheet'));

/**
 * Maintenance page component - shows "under maintenance" message to user
 */
class SpreadsheetPage extends React.Component {
  constructor(props) {
    super(props);

    const parsedQueryParams = queryString.parse(location.search);

    this.state = {
      queryParams: parsedQueryParams || {},
      editionId: props.match.params.editionId,
    };
  }

  updateBrowserURLWithActiveWorksheet = (worksheetId) => {
    this.props.history.replace(`/workbook/${this.state.editionId}/${worksheetId}`);
  }

  componentDidMount() {
    // console.log('SpreadsheetPage componentDidMount', this.state);
  }


  render() {
    return (
      <Spreadsheet
        editionId={this.state.editionId}
        queryParams={this.state.queryParams}
        updateBrowserURLWithActiveWorksheet={this.updateBrowserURLWithActiveWorksheet}
      />
    );
  }
}

export default SpreadsheetPage;
