import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/privateRoute/index';

import StartPage from './containers/startPage';

import ErrorPage from './containers/404';
import HomePage from './containers/home';

import Login from './containers/user/login';
import Signup from './containers/user/signup';
import OauthCallback from './containers/user/loginCallback';
import Logout from './containers/user/logout';
import ShopifyCheckoutPage from './containers/shopifyBilling/checkout';
import ShopifyActiveChargePage from './containers/shopifyBilling/activateCharge';
import Madara from './containers/dondukov/madara';
import DANS from './containers/dondukov/dans';
import MaintenancePage from './containers/maintenance';
import ShopifyLogin from './containers/user/shopifyLogin';
import ShopifyEntry from './containers/user/shopifyEntry';
import LoadingAnimationPage from './components/loadingAnimationPage';
import SpreadsheetPage from './containers/spreadsheetPage';

class Routes extends React.Component {
  render() {
    return (<Router>
      <Suspense fallback={<LoadingAnimationPage />}>
        <div>
          {process.env.MAINTENANCE_MODE === 'on' && <Switch>
            <Route component={MaintenancePage}/>
          </Switch>}
          {(!process.env.MAINTENANCE_MODE || process.env.MAINTENANCE_MODE !== 'on') && <Switch>

            {/* Billing-related*/}
            {/* <Route path="/billing/stripe/success" component={StripeCheckoutSuccessPage} />*/}
            {/* <Route path="/billing/stripe/cancel" component={StripeCheckoutCancelPage} />*/}
            {/* <Route path="/billing/stripe/checkout/:workspaceId" component={StripeCheckoutPage} />*/}
            {/* <Route path="/billing/:workspaceId" component={BillingPage} />*/}
            <Route path="/billing/shopify/checkout/:workspaceId/:storeId" component={ShopifyCheckoutPage} />
            <Route path="/billing/shopify/activate-charge" component={ShopifyActiveChargePage} />

            {/* Core Login and Logout-related*/}
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/oauth/callback" component={OauthCallback} />
            <Route path="/logout" component={Logout} />

            {/* Shopify login-related routes*/}
            <Route path="/shopifyLoginExporter" component={ShopifyLogin}/>
            <Route path="/shopifyLoginBulkEditor" component={ShopifyLogin}/>
            <Route path="/shopifyLoginAnalytics" component={ShopifyLogin}/>
            <Route path="/shopifyEntry" component={ShopifyEntry}/>

            {/* Core app routes*/}
            <Route exact path="/" component={StartPage}/>
            <PrivateRoute exact path="/home" component={HomePage}/>
            <PrivateRoute path="/workbook/:editionId/:worksheetId" component={SpreadsheetPage} />

            {/* Other*/}
            <PrivateRoute path="/dondukov/madara" component={Madara} />
            <PrivateRoute path="/dondukov/dans" component={DANS} />

            <Route component={ErrorPage}/>
          </Switch>}
        </div>
      </Suspense>
    </Router>);
  }
}

export default Routes;
