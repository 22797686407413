import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import asyncJS from 'async';
import { Button, Callout, Classes, Dialog, Intent, Checkbox } from '@blueprintjs/core';
import * as styles from './addShopifyLinkedWorkbookDialog.module.less';
import API from '../../../../config';
import Logger from '../../../../models/logger';
import bulkEditorLogo from '../../../../assets/images/logo/brandmark-green-200.png';
import analyticsLogo from '../../../../assets/images/logo/brandmark-blue-200.png';
// import blankWorkbookImage from '../../../../assets/images/new-workbook-empty.png';
// import templateWorkbookImage from '../../../../assets/images/new-workbook-template.png';
import AxiosClient from '../../../../lib/AxiosClient';

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: '39px',
    background: '#427762',
    fontWeight: '600',
    color: 'white',
    border: 'none', // Remove the border
    boxShadow: 'none', // Remove the box shadow (if any)
    '&:hover': {
      border: 'none', // Prevent border on hover
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '39px',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'white',
    padding: '5px',
  }),
};

/**
 * A dialog that allows the user to add a new Workbook
 */
class AddShopifyLinkedWorkbookDialog extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {
      closeAddShopifyWorkbookDialog: props.closeAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };

    /* Create our logger */
    this.logger = new Logger('AddShopifyLinkedWorkbookDialog');

    this.state = {
      storesSubscriptions: props.storesSubscriptions || {},
      storesAppsInstalled: props.storesAppsInstalled || {},
      selectedShopifyStore: null,
      connectedShopifyStores: [],
      connectedShopifyStoresSelectOptions: [],
      shopifyStoresStillDownloadingData: [], // List of store urls that are still doing their initial data download into Mixtable
      working: false,
      selectedTemplate: null,
      shopifyWorkbookTemplatesForStore: [],
    };

    this.shopifyWorkbookTemplates = {
      basicSection: {
        title: 'Basics',
        templates: [
          {
            id: 'start-from-scratch',
            title: 'Blank Workbook',
            description: 'The same, blank workbook that you get in Excel, and add the data you need',
          },
        ],
      },
      bulkEditorSection: {
        title: 'Spreadsheet Editor App / Excel and CSV Export App',
        icon: bulkEditorLogo,
        templates: [
          // {
          //   id: 'products-and-collections',
          //   title: 'Products and Collections',
          //   description: 'Worksheets for all your products and collections, with the columns folks most often need',
          // },
          {
            id: 'basic-product-info',
            title: 'Basic Product Info',
            description: 'Worksheet for all your products (without metafields)',
          },
          {
            id: 'full-product-info',
            title: 'Full Product Info',
            description: 'Worksheet for all your product variants and their inventories',
          },
          {
            id: 'product-metafields',
            title: 'Product Metafields',
            description: 'Worksheets for all your product and variants metafields',
          },
          {
            id: 'product-inventory',
            title: 'Inventory',
            description: 'Worksheet for all your products and their inventory levels',
          },
          {
            id: 'collections',
            title: 'Collections',
            description: 'Manage your store\'s collections, and the products in them',
          },
          {
            id: 'international-prices',
            title: 'International Prices',
            description: 'Worksheet for all your products and international market prices',
          },
          {
            id: 'google-shopping-fields',
            title: 'Google Shopping',
            description: 'Worksheet for all your products and their Google Shopping fields',
          },
          {
            id: 'orders',
            title: 'Orders',
            description: 'Worksheets for all your orders and order line items',
          },
          {
            id: 'customers',
            title: 'Customers',
            description: 'Worksheet for all your customers',
          },
        ],
      },
      analyticsSection: {
        title: 'For Mixtable Analytics App',
        icon: analyticsLogo,
        templates: [
          {
            id: 'product-annual-sales',
            title: 'Annual Sales',
            description: 'Product net and gross order sales, for all time and for each calendar year',
          },
          {
            id: 'product-monthly-sales',
            title: 'Monthly Sales',
            description: 'Product net and gross order monthly sales',
          },
          {
            id: 'product-orders',
            title: 'Product Orders',
            description: 'Product order numbers for all time, for each calendar year and last 24 months',
          },
          {
            id: 'holiday-sales',
            title: 'Holiday Sales',
            description: 'Product net sales, for the last few holiday seasons',
          },
        ],
      },
    };
  }

  /** Sets the stores select options */
  setStoresSelectOptions = () => {
    const connectedShopifyStores = this.state.storesSubscriptions ? Object.keys(this.state.storesSubscriptions) : [];
    const connectedShopifyStoresSelectOptions = connectedShopifyStores.map(store => ({ value: store, label: store }));

    if (connectedShopifyStores && connectedShopifyStores.length > 0) {
      this.selectStore(connectedShopifyStores[0]);
    }

    this.setState({
      connectedShopifyStores,
      connectedShopifyStoresSelectOptions,
    });
  }

  /* Checks if all our stores have completed their initial data downloads */
  checkIfInitialStoreDownloadHasCompleted = async () => {
    const newStoresStillDownloadingData = [];

    await asyncJS.eachLimit(this.state.connectedShopifyStores || [], 1, async (storeURL) => {
      try {
        const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${storeURL}/downloadStatus/`);

        // console.log(`checking ${storeURL} - response.data: ${JSON.stringify(response.data)}`);

        // If we don't have a response.data, mark store as needs downlad
        if (!response.data) {
          // console.log(`adding ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        } else if (JSON.stringify(response.data).indexOf('in-progress') > -1) {
          // Check if any entry is "downloading". Do a test by stringifying the JSON and trying to find the "in-progress" string. Simpler than iterating over all the fields
          // and somewhat future-proof in case we decide to add new fields

          // console.log(`adding (2) ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        }
      } catch (err) {
        this.logger.error('checkIfInitialStoreDownloadHasCompleted', `Error getting sync task count for ${storeURL}: ${err}`);

        /* Show the error to the user */
        // this.parentFunctions.showError(err);
      } finally {
      }
    });

    this.setState({
      shopifyStoresStillDownloadingData: newStoresStillDownloadingData,
    }); // Update the state with the list of stores still downloading data
  }

  async componentDidMount() {
    await this.setStoresSelectOptions();
    await this.checkIfInitialStoreDownloadHasCompleted();
  }

  /**
   * Selects/Deselects template and saves it to the state
   * @param templateId
   * @param value
   */
  selectTemplate = (templateId, value) => {
    this.setState({
      selectedTemplate: value ? templateId : null,
    });
  }

  /**
   * Selects store, sets selectedStoreCanUseAnalytics and nullifies the selected template
   * @param selectedShopifyStore
   */
  selectStore = (selectedShopifyStore) => {
    this.setState({
      selectedShopifyStore,
      storeHasAnalyticsAppInstalled: this.state.storesAppsInstalled[selectedShopifyStore] && this.state.storesAppsInstalled[selectedShopifyStore].analytics,
      storeHasBulkEditorOrExporterInstalled: this.state.storesAppsInstalled[selectedShopifyStore] && (this.state.storesAppsInstalled[selectedShopifyStore].bulk_editor || this.state.storesAppsInstalled[selectedShopifyStore].bulk_editor),
      selectedTemplate: null,
      shopifyWorkbookTemplatesForStore: [],
    }, () => {
      const shopifyWorkbookTemplatesForStore = [];

      if (this.state.storeHasBulkEditorOrExporterInstalled) {
        shopifyWorkbookTemplatesForStore.push(this.shopifyWorkbookTemplates.basicSection);
        shopifyWorkbookTemplatesForStore.push(this.shopifyWorkbookTemplates.bulkEditorSection);
      }

      if (this.state.storeHasAnalyticsAppInstalled) {
        shopifyWorkbookTemplatesForStore.push(this.shopifyWorkbookTemplates.analyticsSection);
      }

      this.setState({
        shopifyWorkbookTemplatesForStore: shopifyWorkbookTemplatesForStore,
      });
    });
  }

  render() {
    return (
      <Dialog
        isOpen={true}
        onClose={this.parentFunctions.closeAddShopifyWorkbookDialog}
        // icon="new-layer"
        // title="Create a workbook - How do you want to start?"
        style={{ width: 'fit-content' }}
        {...this.state}>
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.component}>

            <div className={styles.templateIntro}>
              Begin with one of our templates, which include pre-made worksheets featuring the data users most
              frequently need. Each worksheet comes with the most commonly used columns, making it an excellent starting
              point. You can easily add or remove data to fit your needs, but these workbooks provides a solid
              foundation for most users
            </div>

            {/* Store selector*/}
            <div>
              {/* You still need to add a store*/}
              {this.state.connectedShopifyStores.length === 0 &&
                <Callout intent={Intent.WARNING} style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: '45px' }}>
                  <div>You need to connect a Shopify store to Mixtable</div>
                </Callout>
              }

              {/* Dropdown showing list of stores*/}
              {this.state.connectedShopifyStores.length > 0 && <div>
                {/* Shopify store selector - show if we have more than one store */}
                {this.state.connectedShopifyStores.length > 1 && <div style={{ margin: '10px 0 10px 0', height: '60px' }}>
                  <Select
                    styles={customSelectStyles}
                    placeholder={'Select a Shopify store'}
                    value={_.find(this.state.connectedShopifyStoresSelectOptions, { value: this.state.selectedShopifyStore })}
                    onChange={((filterValue) => {
                      this.selectStore(filterValue.value);
                    })}
                    options={this.state.connectedShopifyStoresSelectOptions}
                  />
                </div>}
              </div>}

              {this.state.shopifyWorkbookTemplatesForStore.length === 0 && <div style={{ margin: '10px 0 10px 0' }}>
                <Callout intent={Intent.WARNING}>
                  <div>This store does not have any of our apps installed</div>
                </Callout>
              </div>}
            </div>

            {Object.keys(this.state.shopifyWorkbookTemplatesForStore).map(sectionKey => (
              <div key={sectionKey} className={styles.templateSection}>
                <div className={styles.sectionHeading}>
                  {this.state.shopifyWorkbookTemplatesForStore[sectionKey].icon && <img className={styles.sectionIcon} src={this.state.shopifyWorkbookTemplatesForStore[sectionKey].icon} width={12} alt={'logo'}/>}
                  {this.state.shopifyWorkbookTemplatesForStore[sectionKey].title}
                </div>

                {/* Special case if analytics are not installed */}
                {sectionKey === 'analyticsSection' && !this.state.selectedStoreCanUseAnalytics && <Callout className={styles.warningCallout} intent={Intent.PRIMARY}>
                  <p>If you're interested to join the private beta for Mixtable Analytics and get an early preview, please email us at <a href="mailto:team@mixtable.com">team@mixtable.com</a></p>
                </Callout>}

                <div className={styles.templatesList}>
                  {this.state.shopifyWorkbookTemplatesForStore[sectionKey].templates.map(template => (
                    <div key={template.id} className={[styles.template, this.state.selectedTemplate === template.id ? styles.selected : null].join(' ')} onClick={() => {
                      this.selectTemplate(template.id, true);
                    }}>
                      <div>
                        <div className={styles.content}>
                          <div className={styles.title}>
                            {template.title}
                          </div>
                          <div className={styles.description}>
                            {template.description}
                          </div>
                        </div>
                        <Checkbox
                          className={styles.checkboxContainer}
                          // Below is commented out. Checkbox is set based on onClick action above
                          // onChange={(filterValue) => {
                          //   this.selectTemplate(template.id, filterValue.target.checked);
                          // }}
                          disabled={sectionKey === 'analyticsSection' && !this.state.selectedStoreCanUseAnalytics}
                          checked={this.state.selectedTemplate === template.id}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className={styles.createWorkbookButton}>
              <Button
                large={false}
                intent={Intent.PRIMARY}
                onClick={async () => {
                  this.parentFunctions.createWorkbook(this.state.selectedTemplate, this.state.selectedShopifyStore);
                }}
                disabled={!this.state.selectedTemplate}
                text={this.state.selectedTemplate ? 'Create Workbook' : 'First select the type of workbook you want to create'}
                minimal={false}
                fill={true}
                alignText={'center'}
                loading={this.state.working}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddShopifyLinkedWorkbookDialog;
